import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import { find, get, some } from "@app/utils/lodash";
import * as API from "@app/API";
import { NOOP } from "@app/utils/helpers";
import IAction from "@app/types/IAction";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { DataStatus, isDataNotLoaded, runSelector } from "@app/redux/utils";
import { useEffect, useMemo } from "react";
import { denormalize, normalize } from "normalizr";
import { configurationListSchema } from "./schemas";
import { useFeatureFlags } from "../config";
import constants from "@app/utils/constants";
import { useMyProfile } from "@app/utils/hooks";
import { getUserCompanyId } from "@app/entities/users";
import { IntegrationConfig, IntegrationType } from "@app/entities/integrations";

//#region TYPES
export const LOAD_COMPANY_CONFIGURATIONS =
  "ADMINISTRATION::LOAD_COMPANY_CONFIGURATIONS";
export const RESET_COMPANY_CONFIGURATIONS =
  "ADMINISTRATION::RESET_COMPANY_CONFIGURATIONS";
export const UPDATE_CONFIGURATION = "ADMINISTRATION::UPDATE_CONFIGURATION";
export const ADD_CONFIGURATION = "ADMINISTRATION::ADD_CONFIGURATION";
export const DELETE_CONFIGURATION = "ADMINISTRATION::DELETE_CONFIGURATION";
//#endregion

// #region ACTIONS
export const loadCompanyConfigurations = (companyId: number): any => {
  return {
    type: LOAD_COMPANY_CONFIGURATIONS,
    promise: API.loadConfigurations(companyId),
    meta: {
      companyId,
    },
  };
};

export const updateConfiguration = (
  configurationId: number,
  configuration: any,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
): IAction => ({
  type: UPDATE_CONFIGURATION,
  promise: API.updateConfiguration(configurationId, configuration),
  meta: {
    configuration,
    onSuccess,
    onFailure,
  },
});

export const addConfiguration = (
  configuration: any,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
): IAction => {
  return {
    type: ADD_CONFIGURATION,
    promise: API.addConfiguration(configuration),
    meta: {
      configuration,
      onSuccess,
      onFailure,
    },
  };
};

export const deleteConfiguration = (
  configurationId: number,
  onSuccess: () => void = NOOP,
  onFailure: () => void = NOOP
): IAction => {
  return {
    type: DELETE_CONFIGURATION,
    promise: API.deleteConfiguration(configurationId),
    meta: {
      onSuccess,
      onFailure,
    },
  };
};

export const resetCompanyConfigurations = (): IAction => {
  return {
    type: RESET_COMPANY_CONFIGURATIONS,
  };
};
//#endregion

//#region SELECTORS
export const companyConfigurationsState = (state) =>
  state.data.integrations.configurationList;

export const getConfigurations = createSelector(
  companyConfigurationsState,
  (state) => get(state, "configurations") || {}
);

export const getConfigurationIds = createSelector(
  companyConfigurationsState,
  (state) => state.configurationIds
);

export const getConfigurationList = createSelector(
  getConfigurations,
  getConfigurationIds,
  (configurations, configurationIds) => {
    return denormalize(configurationIds, configurationListSchema, {
      configurations,
    });
  }
);

export const getConfiguredIntegrationIds = createSelector(
  getConfigurationList,
  (configurations) =>
    configurations.map((configuration) => configuration.integrationId)
);

export const getConfigurationsStatus = createSelector(
  companyConfigurationsState,
  (state) => state.configurationsStatus
);

export const isConfigurationListLoading = createSelector(
  getConfigurationsStatus,
  (status) => status === DataStatus.Loading
);

export const hasConfigurationListError = createSelector(
  getConfigurationsStatus,
  (status) => status === DataStatus.Error
);

export const hasConfigurationListLoaded = createSelector(
  getConfigurationsStatus,
  (status) => status === DataStatus.Done
);

export const isConfigurationSubmitting = createSelector(
  getConfigurationsStatus,
  (status) => status === DataStatus.Submitting
);

export const getActiveCompanyId = createSelector(
  companyConfigurationsState,
  (state) => state.key
);

export const hasSignatureConfiguration = createSelector(
  getConfigurations,
  (configurations) =>
    some(configurations, (c) => c.integrationType === "SIGNATURE")
);
//#endregion

//#region HOOKS
export const useCompanyConfigurations = (companyId: number) => {
  const dispatch = useDispatch();

  const configurationList = useSelector(getConfigurationList);
  const isLoading = useSelector(isConfigurationListLoading);
  const hasError = useSelector(hasConfigurationListError);
  const hasLoaded = useSelector(hasConfigurationListLoaded);
  const isSubmitting = useSelector(isConfigurationSubmitting);

  useEffect(() => {
    const status = runSelector(getConfigurationsStatus);
    const key = runSelector(getActiveCompanyId);
    if (
      !!companyId &&
      (isDataNotLoaded(status) || (!!key && key !== companyId))
    ) {
      dispatch(loadCompanyConfigurations(companyId));
    }
  }, [companyId, dispatch]);

  return [
    configurationList,
    {
      isLoading,
      hasError,
      hasLoaded,
      isSubmitting,
    },
    {
      loadConfigurations: loadCompanyConfigurations,
      resetConfigurations: resetCompanyConfigurations,
    },
  ];
};

export const useSignatureConfiguration = (): IntegrationConfig => {
  const [myUser] = useMyProfile();
  const [configurations, { hasLoaded }] = useCompanyConfigurations(
    getUserCompanyId(myUser)
  );
  const [hasFeatureFlag] = useFeatureFlags(
    constants.FEATURES_TOGGLE.SEND_FOR_SIGNATURE
  );

  const signatureProvider = useMemo(() => {
    if (!hasFeatureFlag) return null;
    const provider = find(
      configurations,
      (c) => c.integrationType === IntegrationType.Signature
    );
    return provider?.integrationName || null;
  }, [hasFeatureFlag, configurations]);

  return { signatureProvider, hasLoaded };
};

//#endregion

//#region REDUCER
export interface CompanyConfigurationsReduxState {
  configurationsStatus: DataStatus;
  configurations: { [key: number]: any };
  configurationIds: number[];
  key: number;
}

export const initialState: CompanyConfigurationsReduxState = {
  configurationsStatus: DataStatus.NotLoaded,
  configurations: null,
  configurationIds: [],
  // the resource id of the current company (companyId)
  key: null,
};

export default handleActions(
  {
    [LOAD_COMPANY_CONFIGURATIONS]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          configurationsStatus: DataStatus.Loading,
        }),
        failure: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Error,
        }),
        success: (s) => {
          const { entities, result } = normalize(
            get(action, "payload.data", []),
            configurationListSchema
          );
          const { configurations } = entities;
          return {
            ...s,
            key: get(action, "meta.companyId", null),
            configurations,
            configurationIds: result,
            configurationsStatus: DataStatus.Done,
          };
        },
      });
    },
    [DELETE_CONFIGURATION]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Done,
        }),
      });
    },
    [UPDATE_CONFIGURATION]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Done,
        }),
        success: (s) => {
          const configuration = get(action, "meta.configuration", {});
          const configurationId = get(action, "meta.configuration.id", 0);
          const updatedConfiguration = {
            ...s.configurations[configurationId],
            ...configuration,
          };
          return {
            ...s,
            configurations: {
              ...s.configurations,
              [configurationId]: updatedConfiguration,
            },
            configurationsStatus: DataStatus.Done,
          };
        },
      });
    },
    [ADD_CONFIGURATION]: (state, action) => {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Submitting,
        }),
        failure: (s) => ({
          ...s,
          configurationsStatus: DataStatus.Done,
        }),
        success: (s): any => {
          const newConfiguration: any = get(action, "payload.data");
          const configurationId: string = get(newConfiguration, "id");
          return {
            ...s,
            configurations: {
              ...s.configurations,
              [configurationId]: newConfiguration,
            },
            configurationIds: [...s.configurationIds, configurationId],
            configurationsStatus: DataStatus.Done,
          };
        },
      });
    },
    [RESET_COMPANY_CONFIGURATIONS]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
