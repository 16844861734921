import { Role } from "@app/rbac/roles";
import { get, isEmpty } from "@app/utils/lodash";
import constants from "@app/utils/constants";
export type UserStatus = "PENDING" | "ACTIVE" | "SUSPENDED";
const {
  ACCOUNT_TYPE: { PROPRIETARY },
} = constants;

export interface User {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  groups: Role[];
  company: {
    companyId: number;
    name: string;
    trial: boolean;
  };
  status: UserStatus;
  accountType: string;
  title: string;
  latestLoginDate: string;
  verificationDate?: string;
  createdDate?: string;
  scopes?: string[];
  primaryUserProfile?: User;
}

export enum EmailSignature {
  UserName = "USERNAME",
  Title = "TITLE",
  Email = "EMAIL",
}

export const getUserId = (user: User) => get(user, "userId");

export const getUserEmail = (user: User) => get(user, "email");

export const getUserFullName = (user: User) => {
  if (isEmpty(user)) return "";

  const firstName = user.firstName;
  const lastName = user.lastName;

  if (!firstName && !lastName) return "";

  return `${user.firstName} ${user.lastName}`;
};

export const isActiveUser = (user: User): boolean =>
  get(user, "status") === "ACTIVE";

export const isInactiveUser = (user: User): boolean => !isActiveUser(user);

export const isTrialUser = (user: User) => get(user, "company.trial", false);

export const getRolesAndGroups = (user: User) => get(user, "groups") || [];

export const hasUserId = (user: User) => !!get(user, "userId");

// Will be false if the user is switched into another org
export const isLegalSifterUser = (user: User) =>
  get(user, "company.companyId") === 1;

export const hasRole = (user: User, role: Role) =>
  getRolesAndGroups(user).includes(role);

export const isSystemAdmin = (user: User): boolean =>
  hasRole(user, Role.AkordaAdmin);

export const isCompanyAdmin = (user: User): boolean =>
  hasRole(user, Role.CompanyAdmin);

export const isProprietaryUser = (user: User) =>
  getAccountType(user) === PROPRIETARY;

export const isSalesUser = (user: User) => hasRole(user, Role.Sales);

export const isSalesManager = (user: User) => hasRole(user, Role.SalesManager);

export const isSalesUserOrManager = (user: User) =>
  isSalesUser(user) || isSalesManager(user);

export const isSigner = (user: User) => hasRole(user, Role.Signer);

export const getAccountType = (user: User) =>
  get(user, "accountType") || PROPRIETARY;

export const getUserCompanyId = (user: User) => get(user, "company.companyId");

export const getUserTitle = (user: User) => get(user, "title") || "";

export const getEmailSignature = (user: User, settings: any, emailMessage?) => {
  if (!user) {
    return "";
  }
  const userEntities = get(settings, "email-signature", []);
  let signature = emailMessage ? `\n\n${emailMessage}\n\n` : `\n\n`;
  const userName = userEntities.includes(EmailSignature.UserName)
    ? `${getUserFullName(user)}\n`
    : "";
  const title = userEntities.includes(EmailSignature.Title)
    ? `${getUserTitle(user)}\n`
    : "";
  const email = userEntities.includes(EmailSignature.Email)
    ? `${getUserEmail(user)}\n`
    : "";

  return `${signature}${userName}${title}${email}`;
};

export const hasAkordaApplicationAccess = (user: User) => !!user; // we use to check if the user had a known akorda role, but we need to replace with an application scope

export const getSwitchableCompanies = (user: User) => {
  // when switched into a specific org, the switch-user's profile will be on the property `primaryUserProfile`
  // otherwise, we'll check the user's profile directly
  return (
    get(user, "primaryUserProfile.companies") || get(user, "companies") || []
  );
};
