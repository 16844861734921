import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { NOOP } from "@app/utils/helpers";
import { get } from "@app/utils/lodash";
import commonTypes from "@app/_Home/actionTypes";
import { getMyCompanyId } from "@app/_Login/reducers/selectors";
import { notifyFailure } from "@app/utils/notifications";
import { useTranslation } from "@app/utils/hooks";

//#region TYPES
export const LOAD_COMPANY_SETTINGS = "AKORDA::LOAD_COMPANY_SETTINGS";
export const UPDATE_COMPANY_SETTINGS = "AKORDA::UPDATE_COMPANY_SETTINGS";
//#endregion

//#region  ACTIONS
export const loadCompanySettings = (
  companyId: number,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: LOAD_COMPANY_SETTINGS,
  promise: API.getCompanySettings(),
  meta: {
    companyId,
    onSuccess,
    onFailure,
  },
});

export const updateCompanySettings = (
  update: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_COMPANY_SETTINGS,
  promise: API.updateCompanySettings(update),
  meta: {
    onSuccess,
    onFailure,
    update,
  },
});
//#endregion

/* #region  SELECTORS */
export const settingsState = (state) => state.data.settings;

export const getCompanySettings = createSelector(
  settingsState,
  (state) => get(state, "settings") || null
);

export const getCompanySettingsStatus = createSelector(
  settingsState,
  (state) => state.settingsStatus
);

export const isCompanySettingsLoading = createSelector(
  getCompanySettingsStatus,
  (status) => isDataLoading(status)
);

export const isCompanySettingsSubmitting = createSelector(
  getCompanySettingsStatus,
  (status) => isDataSubmitting(status)
);

export const hasCompanySettingsError = createSelector(
  getCompanySettingsStatus,
  (status) => hasDataError(status)
);

export const hasCompanySettingsLoaded = createSelector(
  getCompanySettingsStatus,
  (status) => hasDataLoaded(status)
);

export const getActiveCompanyId = createSelector(
  settingsState,
  (state) => state.key
);

//#endregion

//#region HOOKS
export const useCompanySettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const settings = useSelector(getCompanySettings);
  const isLoading = useSelector(isCompanySettingsLoading);
  const hasError = useSelector(hasCompanySettingsError);
  const hasLoaded = useSelector(hasCompanySettingsLoaded);
  const isSubmitting = useSelector(isCompanySettingsSubmitting);

  useEffect(() => {
    const companyId = runSelector(getMyCompanyId);
    const status = runSelector(getCompanySettingsStatus);
    const key = runSelector(getActiveCompanyId);
    if (isDataNotLoaded(status) || (!!key && key !== companyId)) {
      dispatch(
        loadCompanySettings(companyId, undefined, (e) => {
          if (get(e, "response.status") !== 401) {
            notifyFailure(t("company-settings-page.load-error"));
          }
        })
      );
    }
  }, [dispatch, t]);

  const updateCompanySetting = useCallback(
    (settings, onSuccess = NOOP, onFailure = NOOP) => {
      dispatch(updateCompanySettings(settings, onSuccess, onFailure));
    },
    [dispatch]
  );

  return [
    settings,
    {
      isLoading,
      hasError,
      hasLoaded,
      isSubmitting,
    },
    updateCompanySetting,
  ];
};

//#endregion

/* #region  REDUCER  */
export interface CompanySettingsState {
  settings: { [key: string]: any };
  settingsStatus: DataStatus;
  key: number;
}

export const initialState: CompanySettingsState = {
  // preferences available for the user
  settings: null,
  // status of the preferences: loading, error, completed
  settingsStatus: DataStatus.NotLoaded,
  // the resource id of the current user (userId)
  key: null,
};

export default handleActions(
  {
    [LOAD_COMPANY_SETTINGS]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          settingsStatus: DataStatus.Loading,
        }),
        failure: (s) => {
          const status: number = get(action, "payload.response.status") ?? 500;
          if (status === 401) return { ...initialState }; // reset on 401
          return {
            ...s,
            settingsStatus: DataStatus.Error,
          };
        },
        success: (s) => {
          return {
            ...s,
            key: get(action, "meta.companyId", null),
            settingsStatus: DataStatus.Done,
            settings: get(action, "payload.data") || null,
          };
        },
      }),
    [UPDATE_COMPANY_SETTINGS]: (state, action) =>
      handle(state, action, {
        start: (s) => {
          // set the update immediately so there's no delay in the FE while waiting
          // for the server side update to complete
          const update = (action as any)?.meta?.update ?? {};
          return {
            ...s,
            settings: { ...(s.settings ?? {}), ...update },
            settingsStatus: DataStatus.Submitting,
          };
        },
        failure: (s) => ({
          ...s,
          settingsStatus: DataStatus.Done,
        }),
        success: (s) => ({
          ...s,
          settingsStatus: DataStatus.Done,
          settings: get(action, "payload.data"),
        }),
      }),
    [commonTypes.RESET_APP]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
