import { handleActions } from "redux-actions";
import { handle } from "redux-pack";
import * as API from "@app/API";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import {
  DataStatus,
  hasDataError,
  hasDataLoaded,
  isDataLoading,
  isDataNotLoaded,
  isDataSubmitting,
  runSelector,
} from "@app/redux/utils";
import { NOOP } from "@app/utils/helpers";
import { get } from "@app/utils/lodash";
import commonTypes from "@app/_Home/actionTypes";
import {
  getMetadataId,
  MetadataField,
  resolveMetadataFields,
  useCollapsibleMetadataFields,
} from "@app/entities/metadata";
import { getMyUserId } from "@app/_Login/reducers/selectors";
import { notifyFailure } from "@app/utils/notifications";

//#region TYPES
export const LOAD_PREFERENCES = "AKORDA::LOAD_PREFERENCES";
export const UPDATE_PREFERENCES = "AKORDA::UPDATE_PREFERENCES";
export const SET_PREFERENCES = "AKORDA::SET_PREFERENCES";
//#endregion

//#region  ACTIONS
export const loadPreferences = (
  userId: number,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: LOAD_PREFERENCES,
  promise: API.getPreferences(),
  meta: {
    userId,
    onSuccess,
    onFailure,
  },
});

export const updatePreferences = (
  preferences: any,
  onSuccess = NOOP,
  onFailure = NOOP
) => ({
  type: UPDATE_PREFERENCES,
  promise: API.updatePreferences(preferences),
  meta: {
    onSuccess,
    onFailure,
    update: preferences,
  },
});

export const setPreferences = (preferences: any) => ({
  type: SET_PREFERENCES,
  payload: preferences,
});
//#endregion

/* #region  SELECTORS */
export const preferencesState = (state) => state.data.preferences;

export const getPreferences = createSelector(
  preferencesState,
  (state) => get(state, "preferences") || null
);

export const getPreferencesStatus = createSelector(
  preferencesState,
  (state) => state.preferencesStatus
);

export const isPreferencesLoading = createSelector(
  getPreferencesStatus,
  (status) => isDataLoading(status)
);

export const isPreferencesSubmitting = createSelector(
  getPreferencesStatus,
  (status) => isDataSubmitting(status)
);

export const hasPreferencesError = createSelector(
  getPreferencesStatus,
  (status) => hasDataError(status)
);

export const hasPreferencesLoaded = createSelector(
  getPreferencesStatus,
  (status) => hasDataLoaded(status)
);

export const getActiveUserId = createSelector(
  preferencesState,
  (state) => state.key
);

//#endregion

//#region HOOKS
export const usePreferences = () => {
  const dispatch = useDispatch();

  const preferences = useSelector(getPreferences);
  const isLoading = useSelector(isPreferencesLoading);
  const hasError = useSelector(hasPreferencesError);
  const hasLoaded = useSelector(hasPreferencesLoaded);
  const isSubmitting = useSelector(isPreferencesSubmitting);

  useEffect(() => {
    const userId = runSelector(getMyUserId);
    const status = runSelector(getPreferencesStatus);
    const key = runSelector(getActiveUserId);
    if (isDataNotLoaded(status) || (!!key && key !== userId)) {
      dispatch(
        loadPreferences(userId, undefined, (e) => {
          if (get(e, "response.status") !== 401) {
            notifyFailure("There was a problem loading your preferences.");
          }
        })
      );
    }
  }, [dispatch]);

  return [
    preferences,
    {
      isLoading,
      hasError,
      hasLoaded,
      isSubmitting,
    },
  ];
};

export const usePreference = (name: string, fallbackValue?: any) => {
  const dispatch = useDispatch();
  const [preferences] = usePreferences();
  const preference = get(preferences, name) ?? fallbackValue;
  const updatePreference = useCallback(
    (val) => {
      dispatch(updatePreferences({ [name]: val }));
    },
    [name, dispatch]
  );
  return [preference, updatePreference];
};

export const useMetadataFieldsPreference = (
  name: string,
  metadata: MetadataField[],
  fallbackValue?: any
): any => {
  const [preference, updatePreference] = usePreference(name, fallbackValue);
  const fields = useMemo(
    () => resolveMetadataFields(preference, metadata),
    [preference, metadata]
  );

  const updateFields = useCallback(
    (fields: MetadataField[]) =>
      updatePreference(fields.map((m) => getMetadataId(m))),
    [updatePreference]
  );
  return [fields, updateFields];
};

export const useCollapsibleMetadataFieldsPreference = (
  name: string,
  metadata: MetadataField[],
  fallbackValue?: any
): any => {
  const [preferenceGroups, updatePreferenceGroups] = usePreference(
    name,
    fallbackValue
  );
  return useCollapsibleMetadataFields(
    metadata,
    preferenceGroups,
    updatePreferenceGroups
  );
};

//#endregion

/* #region  REDUCER  */
export interface PreferencesState {
  preferences: { [key: string]: any };
  preferencesStatus: DataStatus;
  key: number;
}

export const initialState: PreferencesState = {
  // preferences available for the user
  preferences: null,
  // status of the preferences: loading, error, completed
  preferencesStatus: DataStatus.NotLoaded,
  // the resource id of the current user (userId)
  key: null,
};

export default handleActions(
  {
    [LOAD_PREFERENCES]: (state, action) =>
      handle(state, action, {
        start: (s) => ({
          ...s,
          key: null,
          preferencesStatus: DataStatus.Loading,
        }),
        failure: (s) => {
          const status: number = get(action, "payload.response.status") ?? 500;
          if (status === 401) return { ...initialState }; // reset on 401
          return {
            ...s,
            preferencesStatus: DataStatus.Error,
          };
        },
        success: (s) => {
          return {
            ...s,
            key: get(action, "meta.userId", null),
            preferencesStatus: DataStatus.Done,
            preferences: get(action, "payload.data") || null,
          };
        },
      }),
    [UPDATE_PREFERENCES]: (state, action) =>
      handle(state, action, {
        start: (s) => {
          // set the update immediately so there's no delay in the FE while waiting
          // for the server side update to complete
          const update = (action as any)?.meta?.update ?? {};
          return {
            ...s,
            preferences: { ...(s.preferences ?? {}), ...update },
            preferencesStatus: DataStatus.Submitting,
          };
        },
        failure: (s) => ({
          ...s,
          preferencesStatus: DataStatus.Done,
        }),
        success: (s) => ({
          ...s,
          preferencesStatus: DataStatus.Done,
          preferences: get(action, "payload.data"),
        }),
      }),
    [SET_PREFERENCES]: (state: any, action) => ({
      ...state,
      preferences: action.payload,
    }),
    [commonTypes.RESET_APP]: () => ({ ...initialState }),
  },
  initialState
);
//#endregion
