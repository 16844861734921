import { useEffect, useMemo, useState } from "react";
import { downloadFileWithAPICall } from "@app/_Common/actions";
import { notifyFailure, notifySuccess } from "@app/utils/notifications";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import QueryFilters from "@app/utils/QueryFilters";
import { exportSearchAsCSV, loadAggregationsForField } from "@app/API";
import { useQueryString } from "@app/utils/hooks";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { lastPromise, NOOP } from "@app/utils/helpers";
import { get, isEmpty, isEqual, omit } from "@app/utils/lodash";
import { SourceType } from "@app/entities/document";
import { Preferences } from "@app/entities/preferences";
import { usePreference } from "@app/redux/data/preferences";
import {
  getSavedSearchId,
  getSavedSearchSourceTypesFilter,
  setSavedSearchSourceTypesFilter,
  useSavedSearch,
} from "@app/redux/data/search/saved-searches";
import { SavedSearch, SavedSearchType } from "@app/types-business/Search";
import { EmailSignature } from "@app/entities/users";

/**
 * Hook that returns a helper object that contains helper functions for common search operations
 */
const loadFilterOptions: any = lastPromise(loadAggregationsForField);

export const useSearchHelpers = (entityType: string = "documents") => {
  const history = useHistory();
  const [query, updateQueryString] = useQueryString();

  const searchHelpers = useMemo(() => {
    // zero-based index numeric version of the "page" param in the query string
    const page = query.page && query.page !== "0" ? +query.page - 1 : 0;

    const { search, orderBy, filters } = query;

    return {
      search: (value: string) => {
        updateQueryString({ search: value || undefined, page: undefined });
      },
      filter: (
        key: string,
        value: string[],
        isExclusion: boolean = false,
        filtersName?: string
      ) => {
        const qs = omit(query, "page"); // remove page so we go to the first page
        const filters = new QueryFilters(qs, filtersName);
        filters.setValue(key, value, isExclusion);
        history.replace(`?${filters.toQueryString()}`);
      },
      clearFilters: (filterParams: string[] = ["filters"]) => {
        const qs = omit(query, ["page", "savedSearch"]);
        filterParams.forEach((filter) => delete qs[filter]);
        history.replace(`?${queryString.stringify(qs)}`);
      },
      getAppliedFilters: (filtersName?: string) => {
        const filters = new QueryFilters(query, filtersName);
        return filters.getFilters();
      },
      query: {
        page,
        orderBy,
        "filters.metadata": filters,
        search,
      },
      loadAggregationsForField:
        (
          additionalQueryParams: Record<string, any> = {},
          onBeforeLoad: (query: Record<string, any>) => Record<string, any> = (
            q
          ) => q
        ) =>
        (fieldName: string, typeahead, onSuccess = NOOP, onFailure = NOOP) => {
          const query: any = {
            search,
            orderBy,
            typeahead,
            "filters.metadata": filters,
            ...additionalQueryParams,
          };
          return loadFilterOptions(fieldName, onBeforeLoad(query), entityType)
            .then(onSuccess)
            .catch(onFailure);
        },
    };
  }, [query, updateQueryString, history, entityType]);

  return searchHelpers;
};

export const defaultSourceTypeFilter = [SourceType.Insights];
export const defaultSourceTypeBackupFilter = [
  SourceType.Insights,
  SourceType.Search,
];

const allSourceTypesFilter = [
  SourceType.Insights,
  SourceType.Search,
  SourceType.Review,
  SourceType.Internal,
  SourceType.External,
];

export const defaultUserSignatureEntities = [
  EmailSignature.UserName,
  EmailSignature.Title,
  EmailSignature.Email,
];

/**
 * Returns the source type filter in the order where it is found,
 * If a saved search id is passed and it is not saved, it tries to return the source types filter from redux state.
 * If a saved search id is passed without changes, it tries to return the source types filter from its filter.
 * By default return the source types filter from user preference (or company settings)
 * @param activeSavedSearchId saved search id
 * @param savedSearchType saved search type
 */
export const useSearchSourceTypeFilter = (
  activeSavedSearchId?: string,
  savedSearchType?: SavedSearchType
) => {
  const { savedSearch } = useSavedSearch(activeSavedSearchId, savedSearchType);

  const currentSavedSearchId = useSelector(getSavedSearchId);

  const dispatch = useDispatch();

  /**
   * When the active saved search switches then clean the source types filter for the non saved search
   */
  useEffect(() => {
    if (
      !!currentSavedSearchId &&
      !!activeSavedSearchId &&
      activeSavedSearchId !== currentSavedSearchId
    ) {
      dispatch(setSavedSearchSourceTypesFilter(activeSavedSearchId, null));
    }
  }, [activeSavedSearchId, currentSavedSearchId, dispatch]);

  /**
   * Source types filter preference
   */
  const [preference, updatePreference] = usePreference(
    Preferences.SearchSourceTypeFilter,
    defaultSourceTypeFilter
  );

  const [sourceTypesPreference, setSourceTypesPreference] =
    useState(preference);

  useEffect(() => {
    if (!isEqual(preference, sourceTypesPreference)) {
      setSourceTypesPreference(preference);
    }
  }, [preference, sourceTypesPreference]);

  /**
   * Source types filter from saved search filter
   */
  const savedSourceTypes = useMemo(() => {
    if (activeSavedSearchId) {
      const filter = getSourceTypesFilter(savedSearch);
      return isEmpty(filter) ? allSourceTypesFilter : filter;
    }
    return null;
  }, [activeSavedSearchId, savedSearch]);

  /**
   * Source types filter from redux state if it is a non saved search.
   */
  const nonSavedSourceTypesFilter = useSelector(
    getSavedSearchSourceTypesFilter
  );

  /**
   * Returns the source types filter from the three possible sources (Non saved search, saved search and preference)
   */
  const sourceTypes = useMemo(() => {
    if (!isEmpty(nonSavedSourceTypesFilter)) {
      return nonSavedSourceTypesFilter; // non saved source types filter
    }
    if (!isEmpty(savedSourceTypes)) {
      return savedSourceTypes; // saved search source types filter
    }
    return sourceTypesPreference; // source types filter preference
  }, [nonSavedSourceTypesFilter, savedSourceTypes, sourceTypesPreference]);

  /**
   * Update the source types filter, if there is a saved search, temporarily save it in redux state if it is not in user preferences
   */
  const update = useMemo(() => {
    if (savedSearch) {
      return (sourceTypes) =>
        dispatch(
          setSavedSearchSourceTypesFilter(
            activeSavedSearchId,
            !isEqual(savedSourceTypes, sourceTypes) ? sourceTypes : null
          )
        );
    }
    return updatePreference;
  }, [
    activeSavedSearchId,
    dispatch,
    savedSearch,
    savedSourceTypes,
    updatePreference,
  ]);

  return [sourceTypes, update, nonSavedSourceTypesFilter];
};

/**
 * Returns a handler function that will export results
 * @param getBaseQuery Function that will return the query string that serves as the base query for search
 */
export const useExportSearch = (query: Record<string, any> = {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sourceTypes] = useSearchSourceTypeFilter();

  // returns a function that takes the columns to export (as a single string) and then
  // exports the results for download
  return (columns: string) => {
    query.includeFields = columns;
    query.urlPattern = `${window.location.origin}/search/document/{documentId}`;
    query.urlCounterpartyPattern = `${window.location.origin}/counterparties/{counterpartyId}`;
    if (!get(query, "filters.sourceTypes") && !isEmpty(sourceTypes)) {
      query["filters.sourceTypes"] = sourceTypes;
    }
    return dispatch(
      downloadFileWithAPICall(
        () => exportSearchAsCSV(query),
        () => notifySuccess(t("search.export.export-success-message")),
        () => notifyFailure(t("search.export.export-failure-message")),
        `akorda-export-search-${moment().format("YYYY-MM-DD-hmmss")}`,
        "text/csv"
      )
    );
  };
};

export const getSourceTypesFilter = (savedSearch: SavedSearch) => {
  if (!savedSearch) return null;
  const queryFilters = new QueryFilters(savedSearch as any);
  return queryFilters.getValue("sourceTypes") || null;
};

export const getFilterString = (savedSearch: SavedSearch) => {
  if (!savedSearch) return null;
  const queryFilters = new QueryFilters(savedSearch as any);
  queryFilters.removeFilter("sourceTypes");
  return queryFilters.toString();
};

export const addSourceTypesFilter = (
  savedSearch: SavedSearch,
  sourceTypes: string[]
) => {
  if (!savedSearch) return null;
  const queryFilters = new QueryFilters(savedSearch as any);
  if (!isEmpty(sourceTypes)) {
    queryFilters.setValue("sourceTypes", sourceTypes);
  }
  return queryFilters.toString();
};
